import {
  Tab as MuiTab,
  Tabs as MuiTabs,
  useMediaQuery,
  type TabsProps as MuiTabsProps,
  type Theme,
} from '@mui/material';
import type { SyntheticEvent, ReactNode, JSX } from 'react';
import { useLocation, Link } from 'react-router-dom';

const useRouteMatch = (pathname: string, patterns: readonly string[]) => {
  const path = pathname.split('/').slice(1)[0];

  for (let i = 0; i < patterns.length; i += 1) {
    if (patterns[i] === pathname) {
      return patterns[i];
    }

    const pattern = patterns[i].replace(/^\//, '');

    if (pattern === path) {
      return patterns[i];
    }

    const patternWithoutParams = pattern.split('?')[0];

    if (patternWithoutParams === path) {
      return patterns[i];
    }
  }

  return null;
};

type TabItem = { label: ReactNode; to: string; icon?: JSX.Element };

export type TabsRouterProps = {
  items: TabItem[];
  ariaLabel?: string;
  onChange?: (path: string) => void;
  sx?: MuiTabsProps['sx'];
};

const a11yProps = (index: number) => {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
};

export const TabsRouter = ({ items, ariaLabel, onChange, sx }: TabsRouterProps) => {
  const { pathname } = useLocation();
  const currentTab = useRouteMatch(
    pathname,
    items.map((item) => item.to),
  );
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const handleChange = (_: SyntheticEvent, path: string) => {
    onChange?.(path);
  };

  return (
    <MuiTabs
      aria-label={ariaLabel}
      onChange={handleChange}
      sx={{ ...sx, '& .MuiTabs-scroller': { overflowY: 'hidden' } }}
      value={currentTab ?? false}
      variant={isMobile ? 'scrollable' : 'standard'}
    >
      {items.map(({ label, to, icon }, index) => (
        <MuiTab
          component={Link}
          icon={icon}
          iconPosition="start"
          key={index}
          label={label}
          sx={sx}
          to={to}
          value={to}
          {...a11yProps(index)}
        />
      ))}
    </MuiTabs>
  );
};
