import {
  isupportClientApiV1 as supportClientApi,
  isupportApiV1 as supportApi,
} from '@deepup/internal-apis';
import { useMutation } from '@tanstack/react-query';

import { useGrpcRestTransport } from '@hooks/useGrpcRestTransport';

export const usePostSupportTicket = () => {
  const transport = useGrpcRestTransport();

  const mutation = useMutation<
    supportApi.CreateSupportTicketResponse,
    { error: { errors: string[] } },
    supportApi.CreateSupportTicketRequest
  >({
    mutationFn: (payload) => {
      const supportClient = new supportClientApi.InternalSupportServiceClient(transport);

      return supportClient.createSupportTicket(payload).response;
    },
  });

  return mutation;
};
