import { Alert, AppBar, Link, Stack, TextField, Typography } from '@mui/material';
import { DataGridPro as DataGrid, type GridColDef } from '@mui/x-data-grid-pro';
import { t } from 'i18next';
import { enqueueSnackbar } from 'notistack';
import { useState, useEffect } from 'react';
import { Trans } from 'react-i18next';

import { TextLink } from '@components/TextLink';
import { useApiProjects } from '@hooks/useApiProjects';
import { useFeatureFlags } from '@hooks/useFeatureFlags';
import { getEnvironment } from '@utils/getEnvironment';

const { cssUrl } = getEnvironment();

export function Page() {
  const [searchQuery, setSearchQuery] = useState('');
  const { data, isLoading, error } = useApiProjects();
  const { isElasticShutdownBannerEnabled } = useFeatureFlags();

  useEffect(() => {
    if (error) {
      enqueueSnackbar(t('pages.map.project.error.api'), { variant: 'error' });
    }
  }, [error]);

  const allProjects = data?.data || [];

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('pages.map.project.table.name'),
      width: 150,
      flex: 1,
      filterable: false,
      renderCell: (params) => (
        <TextLink
          href={`${cssUrl}projects/${params.row.id}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          {params.row.name}
        </TextLink>
      ),
    },
  ];

  const filteredProjects = allProjects.filter((project) => {
    const searchQueryLower = searchQuery.toLowerCase();
    const projectNameLower = project.name.toLowerCase();

    return projectNameLower.includes(searchQueryLower);
  });

  return (
    <>
      {isElasticShutdownBannerEnabled && (
        <AppBar
          sx={(theme) => ({
            zIndex: 1220,
            boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14)',
            borderRadius: 0,
            padding: 0,
            borderTop: `1px solid ${theme.palette.divider}`,
            position: 'fixed',
            top: 113,
            [theme.breakpoints.up('sm')]: {
              top: 145,
            },
          })}
        >
          <Alert
            severity="warning"
            sx={{ borderRadius: 0, justifyContent: 'center' }}
            variant="filled"
          >
            <Trans i18nKey="pages.map.project.elasticBannerText">
              Das Dashboard auf dieser Seite wird am 15. März abgeschaltet. Ein Dashboard für
              abgerechnete Meter ist weiterhin für Administratoren auf Ihrer Organisationsseite in
              unserer
              <Link
                color="secondary"
                href="https://management.deepup.io/organizations"
                target="_blank"
              >
                Organisationsmanagement-App
              </Link>
              verfügbar.
            </Trans>
          </Alert>
        </AppBar>
      )}
      <Stack
        direction="column"
        sx={(theme) => ({
          margin: '0 auto',
          width: '90%',
          [theme.breakpoints.up('md')]: {
            maxWidth: '50%',
          },
          pt: isElasticShutdownBannerEnabled ? 16 : 4,
          [theme.breakpoints.up('sm')]: {
            pt: isElasticShutdownBannerEnabled ? 12 : 4,
          },
          pb: 4,
        })}
      >
        <Stack alignItems="center" direction="row" justifyContent="space-between" mb={2}>
          <Typography fontWeight="bold" gutterBottom variant="h6">
            {t('pages.map.project.title')}
          </Typography>
          <TextField
            label={t('pages.map.project.search.label')}
            onChange={(e) => setSearchQuery(e.target.value)}
            sx={(theme) => ({
              width: theme.spacing(32),
            })}
            value={searchQuery}
          />
        </Stack>

        <DataGrid
          columns={columns}
          disableColumnMenu
          disableRowSelectionOnClick
          initialState={{
            pagination: {
              paginationModel: { pageSize: 10, page: 0 },
            },
          }}
          loading={isLoading}
          pageSizeOptions={[5, 10, 25, 50, 100]}
          pagination
          rows={filteredProjects}
        />
      </Stack>
    </>
  );
}
