import { photoApiV1 as photoApi } from '@deepup/apis';
import { Box, Stack, useMediaQuery, useTheme } from '@mui/material';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Accordion } from '@components/Accordion';
import { SearchNotPossible } from '@components/SearchNotPossible';
import { useListUsedScanners } from '@hooks/useApiDevice';
import { useListPhotos } from '@hooks/useApiPhoto';
import type { ProjectEntity } from '@hooks/useApiProjects';
import { distinct } from '@utils/core';
import type { DateRange } from '@utils/types';

import { DownloadButton } from './DownloadButton/DownloadButton';
import { PhotoFilters } from './PhotoFilters/PhotoFilters';
import { PhotoSidebar } from './PhotoSidebar';
import { ProjectAutocomplete } from './ProjectAutocomplete';
import { PhotoTable } from './Table/Table';
import { isDateInRange, isInCategories, isInScanDevices, isInTextSearch } from './utils';

const defaultToDate = DateTime.now();
const defaultFromDate = defaultToDate.minus({ days: 10 });

const INITTIAL_DATE_RANGE = {
  to: defaultToDate,
  from: defaultFromDate,
};

export const PhotoDownload = () => {
  const { t } = useTranslation();
  const [project, setProject] = useState<ProjectEntity | null>(null);
  const [photo, setPhoto] = useState<photoApi.Photo | null>(null);

  const [dateRange, setDateRange] = useState<DateRange>(INITTIAL_DATE_RANGE);
  const [scanDevices, setScanDevices] = useState<string[]>([]);
  const [photoCategories, setPhotoCategories] = useState<string[]>([]);
  const [search, setSearch] = useState('');

  const { data: photoData, isLoading } = useListPhotos(project?.id || null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    if (photoData) {
      setScanDevices([]);
      setSearch('');
      setDateRange(INITTIAL_DATE_RANGE);
    }
  }, [photoData]);

  const photos = photoData?.items || [];

  const { data: usedScannersData } = useListUsedScanners({
    projectIds: project ? [project.id] : [],
    usedBefore: dateRange?.to ?? INITTIAL_DATE_RANGE.to,
  });

  useEffect(() => {
    if (usedScannersData) {
      setScanDevices([]);
    }
  }, [usedScannersData]);

  const scanners = usedScannersData?.items || [];

  const availableDevices = distinct(scanners.map((item) => item.serialNumber));
  const availableCategories = distinct(photos.map((item) => item.categoryName));

  const filteredPhotos = photos
    .filter(isInTextSearch(search))
    .filter(isDateInRange(dateRange))
    .filter(isInScanDevices(scanDevices, availableDevices, scanners))
    .filter(isInCategories(photoCategories, availableCategories));

  return (
    <Box component="div" display="flex" flexDirection="column" height="100%" id="photo-download">
      <Stack component="div" height="100%" mt={1} p={2} spacing={isMobile ? 2 : 4}>
        {(!isMobile || !project) && (
          <Stack direction={{ xs: 'column', md: 'row' }} justifyContent="space-between" spacing={2}>
            <ProjectAutocomplete project={project} setProject={setProject} />
            {project && (
              <>{!isMobile && <DownloadButton photos={filteredPhotos} project={project} />}</>
            )}
          </Stack>
        )}
        {!!photos.length && (
          <>
            {isMobile ? (
              <Box component="div">
                <Accordion
                  collapsable
                  hasBorder
                  hasPadding
                  title={t('components.photoDownload.filters.title')}
                >
                  <Stack direction="column" spacing={2}>
                    <ProjectAutocomplete project={project} setProject={setProject} />
                    <PhotoFilters
                      availableCategories={availableCategories}
                      availableDevices={availableDevices}
                      dateRange={dateRange}
                      photoCategories={photoCategories}
                      scanDevices={scanDevices}
                      search={search}
                      setDateRange={setDateRange}
                      setPhotoCategories={setPhotoCategories}
                      setScanDevices={setScanDevices}
                      setSearch={setSearch}
                    />
                    {project && <DownloadButton photos={filteredPhotos} project={project} />}
                  </Stack>
                </Accordion>
              </Box>
            ) : (
              <PhotoFilters
                availableCategories={availableCategories}
                availableDevices={availableDevices}
                dateRange={dateRange}
                photoCategories={photoCategories}
                scanDevices={scanDevices}
                search={search}
                setDateRange={setDateRange}
                setPhotoCategories={setPhotoCategories}
                setScanDevices={setScanDevices}
                setSearch={setSearch}
              />
            )}
          </>
        )}

        {project ? (
          <PhotoTable
            isLoading={isLoading}
            photos={filteredPhotos}
            scanners={scanners}
            setPhoto={setPhoto}
          />
        ) : (
          <SearchNotPossible>
            {t('components.photoDownload.errors.noProjectSelected')}
          </SearchNotPossible>
        )}
      </Stack>
      {photo && <PhotoSidebar photo={photo} setPhoto={setPhoto} />}
    </Box>
  );
};
