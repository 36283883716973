import { Box, Paper } from '@mui/material';
import { Outlet } from 'react-router-dom';

import { FilterBox } from '@components/FilterBox';
import { Map, MapStyleControl } from '@components/Map';
import { MapContextProvider } from '@components/MapContextProvider';
import { MapSource } from '@components/MapSource';
import { useFeatureFlags } from '@hooks/useFeatureFlags';
import { useMapView } from '@hooks/useMapView';
import { getEnvironment } from '@utils/getEnvironment';

export const Page = () => {
  const { isInfoBoxEnabled } = useFeatureFlags();

  return (
    <Map>
      <MapContextProvider>
        <MapSource />
        <FilterBox />
        <MapStyleControl />
        {isInfoBoxEnabled && <InfoBox />}
        <Outlet />
      </MapContextProvider>
    </Map>
  );
};

const InfoBox = () => {
  const { zoom } = useMapView();
  const { appVersion } = getEnvironment();

  return (
    <Paper
      sx={{
        position: 'absolute',
        padding: '10px',
        left: '10px',
        bottom: '30px',
        borderRadius: '8px',
      }}
    >
      {appVersion && <Box component="div">Version: {appVersion}</Box>}
      <Box component="div">Zoom: {zoom?.toFixed(2)}</Box>
    </Paper>
  );
};
