import { FilterOutline, LayersOutline, XOutline } from '@deepup/icons';
import { InfoOutlined } from '@mui/icons-material';
import {
  Box,
  Button,
  CardContent,
  Chip,
  Collapse,
  Divider,
  Drawer,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useFlags } from 'flagsmith/react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AccordionWithCheckbox } from '@components/Accordion';
import { FeatureFlag } from '@components/FeatureFlag';
import { FeedbackForm } from '@components/FeedbackForm';
import { SidebarHeader, SidebarHeaderText, SidebarSection } from '@components/Sidebar';
import { useConfig } from '@hooks/useConfig';
import { useFeatureBadge } from '@hooks/useFeatureBadge';
import { useFeatureFlags } from '@hooks/useFeatureFlags';
import { useFilters } from '@hooks/useFilters';
import { useMapFeatures } from '@hooks/useMapFeatures';
import { useMapZoomIsAbove } from '@hooks/useMapZoomIsAbove';
import { useMinDateFromFeatures } from '@hooks/useMinDateFromFeatures';
import { useScanDevicesFromFeatures } from '@hooks/useScanDevicesFromFeatures';
import { getNumberSelected } from '@utils/core';
import { projectCloseupThreshold } from '@utils/mapboxExpressions';

import { PhotoCategorySelect } from './PhotoCategorySelect';
import { ProjectMultiSelect } from './ProjectMultiSelect';
import { TrassesFilters } from './TrassesFilters';
import { CheckBoxGroupInversed } from './fields/CheckBoxGroup';
import { DateRangePicker } from './fields/DateRangePicker';
import { GeocoderField } from './fields/GeocoderField';
import { MultiSelectInversed, itemsToNames, namesToItems } from './fields/MultiSelect';

const drawerWidth = 350;

export const FilterBox = () => {
  const { drawerStyle } = useConfig();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    scanDevices,
    setScanDevices,
    dateRange,
    setDateRange,
    showScans,
    setShowScans,
    showPhotos,
    setShowPhotos,
    photoCategories,
    setPhotoCategories,
    showTrasses,
    showPlandata,
    setShowPlandata,
    surfaceClassification,
    setSurfaceClassification,
  } = useFilters();
  const features = useMapFeatures(
    {
      source: 'trajectories-source',
      sourceLayer: 'artrajectory',
    },
    {
      source: 'photos-source',
      sourceLayer: 'photo',
      filter: ['to-boolean', showPhotos],
    },
    {
      source: 'trass-source',
      sourceLayer: 'trass_segmented',
      filter: ['to-boolean', showTrasses],
    },
    {
      source: 'oldscans-source',
      sourceLayer: 'notrajectory',
    },
  );

  const { isZoomAboveThreshold } = useMapZoomIsAbove(projectCloseupThreshold, 1500);

  const scanDevicesAvailable = useScanDevicesFromFeatures(features, scanDevices);

  const availablePhotoCategories = useMemo(
    () =>
      [
        ...new Set([
          ...photoCategories,
          ...features
            .filter((feature) => feature.properties?.category_name)
            .map((feature) => feature.properties?.category_name),
        ]),
      ].sort(Intl.Collator().compare),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [features, JSON.stringify(photoCategories)],
  );

  const minDate = useMinDateFromFeatures(features);

  const flags = useFlags(['oldscans_enabled']);

  const { isOldscansEnabled, isFeedbackFormEnabled } = useFeatureFlags();

  const flagOldScans = flags['oldscans_enabled'];

  const BadgeOldScans = useFeatureBadge(flagOldScans);

  return (
    <>
      {isMobile && (
        <Box component="div" sx={{ margin: '1rem' }}>
          <Button onClick={() => setIsOpen(true)} variant="contained">
            {t('components.filterBox.sidebarHeaderText')}
          </Button>
        </Box>
      )}
      <Drawer
        anchor="left"
        open={isOpen}
        sx={{
          width: isMobile ? '100%' : drawerWidth,
          [`& .MuiDrawer-paper`]: {
            width: isMobile ? '100%' : drawerWidth,
            boxSizing: 'border-box',
            ...drawerStyle,
          },
        }}
        variant={isMobile ? 'temporary' : 'permanent'}
      >
        <SidebarHeader>
          <Box
            component="div"
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              alignItems: 'center',
            }}
          >
            <SidebarHeaderText>{t('components.filterBox.sidebarHeaderText')}</SidebarHeaderText>
            {isMobile && (
              <IconButton onClick={() => setIsOpen(false)}>
                <XOutline />
              </IconButton>
            )}
          </Box>
        </SidebarHeader>
        <CardContent>
          <Stack direction="column" spacing="20px">
            <GeocoderField />

            <SidebarSection>
              <FilterOutline /> {t('components.filterBox.filterSubHeading')}
            </SidebarSection>
            <Box component="div">
              <ProjectMultiSelect />
            </Box>
            <Stack direction="column" spacing="12px">
              <DateRangePicker
                dateRange={dateRange}
                minDate={minDate}
                setDateRange={setDateRange}
              />
            </Stack>
            <Box component="div">
              <MultiSelectInversed
                disabled={!scanDevicesAvailable.length}
                helperText={
                  !scanDevicesAvailable.length && isZoomAboveThreshold
                    ? t('components.filterBox.scanDeviceSelect.helperText')
                    : undefined
                }
                items={scanDevicesAvailable}
                label={t('components.filterBox.scanDeviceSelect.label')}
                setUnselectedItems={(items) => setScanDevices(itemsToNames(items))}
                unselectedItems={namesToItems(scanDevices)}
              />
            </Box>

            <SidebarSection>
              <LayersOutline /> {t('components.filterBox.mapLayers.subHeading')}
            </SidebarSection>

            <Collapse in={!isZoomAboveThreshold} unmountOnExit>
              <Stack direction="row" spacing="12px">
                <InfoOutlined />
                <Typography>{t('components.filterBox.mapLayers.infoText')}</Typography>
              </Stack>
            </Collapse>

            <Box component="div">
              <AccordionWithCheckbox
                checked={showScans}
                collapsable
                hasPadding={false}
                onCheck={setShowScans}
                postTitle={
                  <Tooltip title={t('components.filterBox.mapLayers.scans.tooltipBeta')}>
                    <Chip
                      color="primary"
                      label="BETA"
                      sx={(theme) => ({
                        backgroundColor: theme.palette.info.main,
                        fontWeight: theme.typography.fontWeightBold,
                      })}
                    />
                  </Tooltip>
                }
                title="Scans"
              >
                <CheckBoxGroupInversed
                  availableItems={
                    isOldscansEnabled
                      ? ['trench', 'surface', 'undefined', 'noTrajectory']
                      : ['trench', 'surface', 'undefined']
                  }
                  disabled={!showScans}
                  items={surfaceClassification}
                  mainDivider={
                    isOldscansEnabled ? (
                      <Divider textAlign="left">
                        {t('components.filterBox.scansFilter.mainDivider')}
                      </Divider>
                    ) : null
                  }
                  setItems={setSurfaceClassification}
                  subDivider={
                    isOldscansEnabled ? (
                      <Divider textAlign="left">
                        {t('components.filterBox.scansFilter.subDivider')}
                      </Divider>
                    ) : null
                  }
                  transformLabel={(label) =>
                    isOldscansEnabled && label === 'noTrajectory' ? (
                      <BadgeOldScans>
                        {t(
                          `components.filterBox.scansFilter.surfaceClassification.${label as 'trench' | 'surface' | 'noTrajectory' | 'undefined'}`,
                        )}
                      </BadgeOldScans>
                    ) : (
                      t(
                        `components.filterBox.scansFilter.surfaceClassification.${label as 'trench' | 'surface' | 'noTrajectory' | 'undefined'}`,
                      )
                    )
                  }
                />
              </AccordionWithCheckbox>

              <AccordionWithCheckbox
                checked={showPhotos}
                onCheck={setShowPhotos}
                postTitle={
                  !showPhotos ? null : (
                    <Chip
                      color="primary"
                      label={`${
                        !photoCategories.length
                          ? t('components.filterBox.mapLayers.photos.activeInfo.all')
                          : getNumberSelected(availablePhotoCategories, photoCategories)
                      } ${t('components.filterBox.mapLayers.photos.activeInfo.active')}`}
                    />
                  )
                }
                title={t('components.filterBox.mapLayers.photos.title')}
              >
                <PhotoCategorySelect
                  availablePhotoCategories={availablePhotoCategories}
                  photoCategories={photoCategories}
                  setPhotoCategories={setPhotoCategories}
                />
              </AccordionWithCheckbox>

              <TrassesFilters title={t('components.filterBox.mapLayers.trass.title')} />

              <FeatureFlag name="plandata_enabled">
                {({ Badge }) => (
                  <AccordionWithCheckbox
                    checked={showPlandata}
                    collapsable={false}
                    onCheck={setShowPlandata}
                    title={<Badge>{t('components.filterBox.mapLayers.planData.title')}</Badge>}
                  />
                )}
              </FeatureFlag>
            </Box>
          </Stack>
        </CardContent>
      </Drawer>
      {isFeedbackFormEnabled && <FeedbackForm />}
    </>
  );
};
