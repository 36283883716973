import { Auth0Provider } from '@auth0/auth0-react';
import { DeepUpThemeProvider } from '@deepup/mui-theme-deepup';
import { CssBaseline } from '@mui/material';
import { deDE as muiCoreDE, enUS as muiCoreEN } from '@mui/material/locale';
import { deDE as dataGridDE, enUS as dataGridEN } from '@mui/x-data-grid-pro/locales';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterLuxon } from '@mui/x-date-pickers-pro/AdapterLuxon';
import { deDE as datePickerDE, enUS as datePickerEN } from '@mui/x-date-pickers-pro/locales';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import flagsmith from 'flagsmith';
import { FlagsmithProvider } from 'flagsmith/react';
import { SnackbarProvider } from 'notistack';
import { useTranslation } from 'react-i18next';

import { Auth } from '@components/Auth';
import { FlagsmithInit } from '@components/FlagsmithInit';
import { SnackbarNotification } from '@components/Snackbar';
import { GrpcRestTransportProvider } from '@hooks/useGrpcRestTransport';
import { getEnvironment } from '@utils/getEnvironment';

import { AppRoutes } from './AppRoutes';

import './App.css';
import '@utils/sentrySetup';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // invalidate manually after mutation calls
      staleTime: Infinity,
      retryOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: 0,
    },
  },
});

const componentTranslations: Record<string, object[]> = {
  de: [muiCoreDE, datePickerDE, dataGridDE],
  en: [muiCoreEN, datePickerEN, dataGridEN],
};

export const App = () => {
  const { auth0ClientId, auth0Domain, auth0Audience } = getEnvironment();
  const { i18n } = useTranslation();

  return (
    <>
      <SnackbarProvider
        Components={{
          default: SnackbarNotification,
          success: SnackbarNotification,
          error: SnackbarNotification,
          warning: SnackbarNotification,
          info: SnackbarNotification,
        }}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      />
      <FlagsmithProvider flagsmith={flagsmith}>
        <DeepUpThemeProvider args={componentTranslations[i18n.language]}>
          <CssBaseline />
          <Auth0Provider
            authorizationParams={{
              redirect_uri: window.location.origin,
              audience: auth0Audience,
            }}
            clientId={auth0ClientId ?? ''}
            domain={auth0Domain ?? ''}
          >
            <Auth>
              <FlagsmithInit>
                <GrpcRestTransportProvider>
                  <QueryClientProvider client={queryClient}>
                    <LocalizationProvider adapterLocale={i18n.language} dateAdapter={AdapterLuxon}>
                      <AppRoutes />
                    </LocalizationProvider>
                  </QueryClientProvider>
                </GrpcRestTransportProvider>
              </FlagsmithInit>
            </Auth>
          </Auth0Provider>
        </DeepUpThemeProvider>
      </FlagsmithProvider>
    </>
  );
};
