import { type BadgeProps } from '@mui/material';
import { useFlags } from 'flagsmith/react';
import { type ComponentType, type ReactNode } from 'react';

import { useFeatureBadge } from '@hooks/useFeatureBadge';

export type FeatureFlagProps = {
  name:
    | 'infobox_enabled'
    | 'plandata_enabled'
    | 'cockpit_migration_enabled'
    | 'oldscans_enabled'
    | 'feedback_form_enabled';

  children?: ReactNode | ((params: { Badge: ComponentType<BadgeProps> }) => ReactNode);
};

export const FeatureFlag = ({ name, children }: FeatureFlagProps) => {
  const flags = useFlags([name]);
  const flag = flags[name];
  const Badge = useFeatureBadge(flag);

  if (!flag?.enabled) {
    return null;
  }

  if (typeof children !== 'function') {
    return children;
  }

  return children({ Badge });
};
