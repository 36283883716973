import { Clear } from '@mui/icons-material';
import { FormControl, IconButton, Stack, TextField } from '@mui/material';
import type { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { DateRangePicker } from '@components/FilterBox/fields/DateRangePicker';
import { SelectBoxMultiInversed } from '@components/FilterBox/fields/SelectBoxMulti';
import type { DateRange } from '@utils/types';

interface FiltersProps {
  dateRange: DateRange;
  setDateRange: Dispatch<SetStateAction<DateRange>>;
  availableCategories: string[];
  photoCategories: string[];
  setPhotoCategories: Dispatch<SetStateAction<string[]>>;
  availableDevices: string[];
  scanDevices: string[];
  setScanDevices: Dispatch<SetStateAction<string[]>>;
  search: string;
  setSearch: (search: string) => void;
}

export const PhotoFilters = ({
  dateRange,
  setDateRange,
  availableCategories,
  photoCategories,
  setPhotoCategories,
  availableDevices,
  scanDevices,
  setScanDevices,
  search,
  setSearch,
}: FiltersProps) => {
  const { t } = useTranslation();

  return (
    <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
      <DateRangePicker dateRange={dateRange} setDateRange={setDateRange} />
      <SelectBoxMultiInversed
        availableItems={availableCategories}
        items={photoCategories}
        label={t('components.filterBox.photoCategorySelect.label')}
        setItems={setPhotoCategories}
      />
      <SelectBoxMultiInversed
        availableItems={availableDevices}
        items={scanDevices}
        label={t('components.filterBox.scanDeviceSelect.label')}
        setItems={setScanDevices}
      />
      <FormControl fullWidth>
        <TextField
          InputProps={{
            endAdornment: (
              <IconButton
                onClick={() => setSearch('')}
                sx={{ display: search ? 'visible' : 'none' }}
              >
                <Clear />
              </IconButton>
            ),
          }}
          fullWidth
          label={t('pages.map.photos.search.label')}
          onInput={(e) => setSearch((e.target as HTMLInputElement).value)}
          placeholder={t('pages.map.photos.search.label')}
          value={search}
        />
      </FormControl>
    </Stack>
  );
};
