import {
  commentApiV1 as commentApi,
  deviceManagementApiV1,
  photoApiV1 as photoApi,
} from '@deepup/apis';
import {
  Box,
  CircularProgress,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
  type Theme,
} from '@mui/material';
import {
  DataGridPro as DataGrid,
  type GridColDef,
  type GridListColDef,
  type GridRenderCellParams,
} from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';

import { indexList } from '@utils/core';
import { fromTimestamp, localizeTimestampApi } from '@utils/timeFormatting';

import { CommentCell, GeoPositionSolutionCell, ImageCell, MultilineText } from './TableCell';

type Props = {
  photos: photoApi.Photo[];
  setPhoto: (item: photoApi.Photo) => void;
  isLoading: boolean;
  scanners: deviceManagementApiV1.ScannerPreview[];
};

function ListViewCell(params: GridRenderCellParams) {
  const { city, place } = params.row.location;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.between('sm', 'md'));

  return (
    <Box
      component="div"
      display="flex"
      flexDirection={isTablet ? 'row' : 'column'}
      height="100%"
      width="100%"
    >
      <Box
        component="div"
        sx={{
          pointerEvents: isMobile ? 'none' : 'auto',
          width: '100%',
          height: isTablet ? '350px' : '300px',
          overflow: 'hidden',
        }}
      >
        <ImageCell {...params.row} />
      </Box>
      <Box
        component="div"
        display="flex"
        flexWrap="wrap"
        sx={{
          width: '100%',
          height: '50px',
          padding: 1,
        }}
      >
        <Typography
          fontSize={14}
          fontWeight={'bold'}
          noWrap
          variant="body2"
          width={isTablet ? '100%' : '75%'}
        >
          {`${place} ${city}`}
        </Typography>
        <Typography fontSize={14} marginLeft={isTablet ? 0 : 'auto'} noWrap variant="body2">
          {localizeTimestampApi(params.row.recordedAt)}
        </Typography>
        <Typography flexGrow={1} fontSize={14} noWrap variant="body2" width="100%">
          {params.row.categoryName}
        </Typography>
      </Box>
    </Box>
  );
}

const listColDef: GridListColDef = {
  field: 'listColumn',
  renderCell: ListViewCell,
};

export const PhotoTable = ({ photos, scanners, setPhoto, isLoading }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const scannersIndex = indexList(scanners);
  const columns: GridColDef<photoApi.Photo>[] = [
    {
      disableColumnMenu: true,
      field: 'id',
      filterable: false,
      headerName: t('components.photoDownload.table.photo'),
      hideable: true,
      width: 100,
      sortable: false,
      type: 'string',
      renderCell: (params) => <ImageCell {...params.row} />,
    },
    {
      disableColumnMenu: true,
      field: 'address',
      filterable: false,
      headerName: t('components.photoDownload.table.address'),
      hideable: true,
      flex: 1.5,
      type: 'string',
      valueGetter: (_, row) => {
        if (row.location) {
          const { city, place } = row.location;

          return `${place} ${city}`;
        }

        return '';
      },
      renderCell: (params) => {
        if (params.row.location) {
          const { city, place } = params.row.location;

          return <MultilineText text={`${place} ${city}`} />;
        }
      },
      cellClassName: 'centered-cell',
    },
    {
      disableColumnMenu: true,
      field: 'comment-device',
      filterable: false,
      headerName: t('components.photoDownload.table.commentDevice'),
      hideable: false,
      flex: 1.2,
      type: 'string',
      valueGetter: (_, row) =>
        row.comments
          .filter((item) => item.type === commentApi.CommentType.DEVICE)
          .map((item) => item.text)
          .join(''),
      renderCell: (params) => {
        const text = params.row.comments
          .filter((item) => item.type === commentApi.CommentType.DEVICE)
          .map((item) => item.text)
          .join('\n');

        return <MultilineText text={text} />;
      },
      cellClassName: 'centered-cell',
    },
    {
      disableColumnMenu: true,
      field: 'comment-note',
      filterable: false,
      headerName: t('components.photoDownload.table.commentNote'),
      hideable: false,
      flex: 1,
      type: 'string',
      valueGetter: (_, row) =>
        row.comments
          .filter((item) => item.type === commentApi.CommentType.NOTE)
          .map((item) => item.text)
          .join(''),

      renderCell: (params) => {
        return <CommentCell photo={params.row} />;
      },
      cellClassName: 'centered-cell',
    },
    {
      disableColumnMenu: true,
      field: 'deviceId',
      filterable: false,
      headerName: t('components.filterBox.scanDeviceSelect.label'),
      hideable: false,
      valueGetter: (_, row) => row.categoryName,
      flex: 1,
      maxWidth: 160,
      type: 'string',
      renderCell: (params) => {
        if (!scanners.length) {
          return <CircularProgress size={20} />;
        }
        const scanner = scannersIndex[params.row.deviceId];

        return (
          <MultilineText text={scanner ? scanner.serialNumber : t('common.noDataAvailable')} />
        );
      },
      cellClassName: 'centered-cell',
    },
    {
      disableColumnMenu: true,
      field: 'category',
      filterable: false,
      headerName: t('components.photoDownload.table.category'),
      hideable: false,
      valueGetter: (_, row) => row.categoryName,
      flex: 1,
      maxWidth: 160,
      type: 'string',
      renderCell: (params) => <MultilineText text={params.row.categoryName} />,
      cellClassName: 'centered-cell',
    },
    {
      disableColumnMenu: true,
      field: 'geoPositionSolution',
      filterable: false,
      headerName: t('components.photoDownload.table.precision'),
      hideable: false,
      flex: 1,
      maxWidth: 100,
      type: 'string',
      renderCell: (params) => (
        <GeoPositionSolutionCell geoPositionSolution={params.row.geoPositionSolution} />
      ),
      cellClassName: 'centered-cell',
    },
    {
      disableColumnMenu: true,
      field: 'recordedDate',
      filterable: false,
      headerName: t('components.photoDownload.table.date'),
      hideable: false,
      flex: 1,
      maxWidth: 100,
      type: 'string',
      valueGetter: (_, row) => row.recordedAt && fromTimestamp(row.recordedAt),
      sortComparator: (dt1, dt2) => {
        if (dt1 < dt2) {
          return -1;
        } else if (dt1 > dt2) {
          return 1;
        } else {
          return 0;
        }
      },
      renderCell: (params) => {
        return localizeTimestampApi(params.row.recordedAt);
      },
    },
  ];

  return (
    <Paper
      elevation={1}
      sx={{
        height: (theme: Theme) =>
          isMobile ? `calc(100% - ${theme.spacing(9)})` : `calc(100vh - ${theme.spacing(55)})`,
      }}
    >
      <DataGrid
        columns={columns}
        disableRowSelectionOnClick
        getRowId={(row) => row.id}
        initialState={{
          sorting: {
            sortModel: [{ field: 'recordedDate', sort: 'desc' }],
          },
        }}
        loading={isLoading}
        onRowClick={(params) => setPhoto(params.row)}
        pagination
        rowHeight={isMobile ? 355 : 72}
        rows={photos}
        sx={{
          '& .centered-cell': {
            display: 'flex',
            alignItems: 'center',
          },
          '& .MuiDataGrid-cell': {
            padding: 0,
          },
          padding: isMobile ? 0 : theme.spacing(1),
        }}
        unstable_listColumn={listColDef}
        unstable_listView={isMobile}
      />
    </Paper>
  );
};
