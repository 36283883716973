import { XOutline } from '@deepup/icons';
import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  Box,
  TextField,
  Typography,
  DialogActions,
  Button,
  CircularProgress,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useThemeMode } from '@hooks/useThemeMode';

type FeedbackDialogProps = {
  open: boolean;
  message: string;
  isSubmitting: boolean;
  onClose: () => void;
  onMessageChange: (message: string) => void;
  onSubmit: () => void;
};

export const FeedbackDialog = ({
  open,
  message,
  isSubmitting,
  onClose,
  onMessageChange,
  onSubmit,
}: FeedbackDialogProps) => {
  const { t } = useTranslation();
  const { isDarkMode } = useThemeMode();

  return (
    <Dialog
      maxWidth="md"
      onClose={(_, reason) => reason === 'backdropClick' && onClose()}
      open={open}
      sx={{ '& .MuiDialog-paperScrollPaper': { overflowX: 'hidden' } }} // prevent horizontal overflow during typing
    >
      <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        {t('components.feedbackForm.dialogHeader')}
        <IconButton onClick={onClose} size="small">
          <XOutline fill={isDarkMode ? '#ccc' : undefined} fontSize="inherit" />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers>
        <Box autoComplete="off" component="form" noValidate>
          <TextField
            fullWidth
            label={t('components.feedbackForm.textFieldLabel')}
            maxRows={6}
            minRows={6}
            multiline
            onChange={(e) => onMessageChange(e.target.value)}
            placeholder={t('components.feedbackForm.textFieldPlaceholder')}
            value={message}
          />
          <Typography mt={1} variant="body2">
            {t('components.feedbackForm.dialogHelperText')}
          </Typography>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button color="secondary" onClick={onClose} variant="outlined">
          {t('components.feedbackForm.buttonCancel')}
        </Button>
        <Button
          disabled={isSubmitting || message === ''}
          endIcon={isSubmitting && <CircularProgress size={20} />}
          onClick={onSubmit}
          variant="contained"
        >
          {isSubmitting
            ? t('components.feedbackForm.buttonSumbitting')
            : t('components.feedbackForm.buttonSumbit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
