import { CalendarWeekOutline } from '@deepup/icons';
import { useMediaQuery, useTheme } from '@mui/material';
import {
  SingleInputDateRangeField,
  type DateRange as PickerDateRange,
  type PickersTimezone,
} from '@mui/x-date-pickers-pro';
import { DateRangePicker as DateRangePickerPro } from '@mui/x-date-pickers-pro/DateRangePicker';
import { DateTime } from 'luxon';
import type { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import type { DateRange } from '@utils/types';

export const DateRangePicker = ({
  dateRange,
  setDateRange,
  disabled,
  minDate,
  maxDate,
  timezone,
}: {
  dateRange: DateRange;
  setDateRange: Dispatch<SetStateAction<DateRange>>;
  minDate?: DateTime;
  maxDate?: DateTime;
  dateProp?: string;
  disabled?: boolean;
  timezone?: PickersTimezone;
}) => {
  const today = DateTime.local();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const onChange = (val: PickerDateRange<DateTime>) => {
    setDateRange({ from: val[0], to: val[1] });
  };

  return (
    <DateRangePickerPro
      disableFuture
      disabled={disabled}
      label={t('components.dateRange.label')}
      maxDate={maxDate}
      minDate={minDate}
      onChange={onChange}
      slotProps={{
        actionBar: {
          actions: ['clear'],
        },
        textField: {
          InputProps: { endAdornment: <CalendarWeekOutline fontSize={24} /> },
        },
        shortcuts: {
          sx: {
            '& :has(.Mui-disabled)': {
              // hide disabled shortcuts
              display: 'none',
            },
          },
          items: isMobile
            ? []
            : [
                {
                  label: t('components.dateRange.shortcuts.today'),
                  getValue: () => [today, today],
                },
                {
                  label: t('components.dateRange.shortcuts.yesterday'),
                  getValue: () => [today.minus({ days: 1 }), today.minus({ days: 1 })],
                },
                {
                  label: t('components.dateRange.shortcuts.7Days'),
                  getValue: () => [today.minus({ days: 6 }), today],
                },
                {
                  label: t('components.dateRange.shortcuts.currentWeek'),
                  getValue: () => [today.startOf('week'), today],
                },
                {
                  label: t('components.dateRange.shortcuts.lastWeek'),
                  getValue: () => [
                    today.minus({ weeks: 1 }).startOf('week'),
                    today.minus({ weeks: 1 }).endOf('week'),
                  ],
                },
                {
                  label: t('components.dateRange.shortcuts.currentMonth'),
                  getValue: () => [today.startOf('month'), today],
                },
                {
                  label: t('components.dateRange.shortcuts.lastMonth'),
                  getValue: () => [
                    today.minus({ months: 1 }).startOf('month'),
                    today.minus({ months: 1 }).endOf('month'),
                  ],
                },
              ],
        },
      }}
      slots={{
        field: SingleInputDateRangeField,
      }}
      sx={{ width: '100%' }}
      timezone={timezone}
      value={[dateRange.from, dateRange.to]}
    />
  );
};
