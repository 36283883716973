import { useFlags } from 'flagsmith/react';

const PHOTOS_CLUSTER_ZOOM_THRESHOLD = 15;

export const useFeatureFlags = () => {
  const {
    plandata_enabled,
    infobox_enabled,
    photos_cluster_zoom_threshold,
    cockpit_migration_enabled,
    raw_images_enabled,
    filter_trench_profile_enabled,
    oldscans_enabled,
    feedback_form_enabled,
    elastic_shutdown_banner_enabled,
  } = useFlags([
    'plandata_enabled',
    'infobox_enabled',
    'photos_cluster_zoom_threshold',
    'cockpit_migration_enabled',
    'raw_images_enabled',
    'filter_trench_profile_enabled',
    'oldscans_enabled',
    'feedback_form_enabled',
    'elastic_shutdown_banner_enabled',
  ]);

  return {
    isPlandataEnabled: plandata_enabled.enabled,
    isInfoBoxEnabled: infobox_enabled.enabled,
    isCockpitMigrationEnabled: cockpit_migration_enabled.enabled,
    isRawImagesEnabled: raw_images_enabled.enabled,
    isFilterTrenchProfileEnabled: filter_trench_profile_enabled.enabled,
    isOldscansEnabled: oldscans_enabled.enabled,
    isFeedbackFormEnabled: feedback_form_enabled.enabled,
    isElasticShutdownBannerEnabled: elastic_shutdown_banner_enabled.enabled,
    photosClusterZoomThreshold:
      photos_cluster_zoom_threshold.enabled && Number(photos_cluster_zoom_threshold.value)
        ? Number(photos_cluster_zoom_threshold.value)
        : PHOTOS_CLUSTER_ZOOM_THRESHOLD,
  };
};
