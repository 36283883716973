import { ChatBubbleLinesFilled } from '@deepup/icons';
import { Fab, useMediaQuery, useTheme } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FeedbackDialog } from '@components/FeedbackForm';
import { usePostSupportTicket } from '@hooks/useApiSupport';
import { useMapFeatures } from '@hooks/useMapFeatures';

type FeedbackPayload = {
  content: string;
  projectIds: string[];
  locationUrl: string;
};

export const useFeedbackForm = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState('');
  const queryClient = useQueryClient();
  const mapFeatures = useMapFeatures({
    source: 'projects-source',
    sourceLayer: 'project',
  });

  const { mutateAsync: postSupportTicket, isPending: isSubmitting } = usePostSupportTicket();

  const handleSubmit = async () => {
    const payload: FeedbackPayload = {
      content: message,
      projectIds: mapFeatures.map((feature) => feature.properties?.id),
      locationUrl: window.location.href,
    };

    await postSupportTicket(payload, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['ticket'] });
        enqueueSnackbar(t('components.feedbackForm.submitSuccess'), { variant: 'success' });
        handleClose();
      },
      onError: (error) => {
        console.error('Error submitting feedback:', error);
        enqueueSnackbar(t('components.feedbackForm.submitError'), { variant: 'error' });
      },
    });
  };

  const handleClose = () => {
    setIsOpen(false);
    setMessage('');
  };

  return {
    isOpen,
    message,
    isSubmitting,
    setIsOpen,
    setMessage,
    handleSubmit,
    handleClose,
  };
};

export const FeedbackForm = () => {
  const [buttonIsHovered, setButtonIsHovered] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { isOpen, message, isSubmitting, setIsOpen, setMessage, handleSubmit, handleClose } =
    useFeedbackForm();

  return (
    <>
      <Fab
        color="primary"
        onClick={() => setIsOpen(true)}
        onMouseEnter={() => setButtonIsHovered(true)}
        onMouseLeave={() => setButtonIsHovered(false)}
        sx={{ position: 'fixed', left: isMobile ? 5 : '370px', bottom: isMobile ? 5 : 20 }}
        variant="extended"
      >
        <ChatBubbleLinesFilled fontSize="20" />
        {buttonIsHovered && '  Feedback'}
      </Fab>

      <FeedbackDialog
        isSubmitting={isSubmitting}
        message={message}
        onClose={handleClose}
        onMessageChange={setMessage}
        onSubmit={handleSubmit}
        open={isOpen}
      />
    </>
  );
};
